import {Link, PageProps, graphql} from 'gatsby'
import React, {useEffect, useState} from 'react'

import ClientCard from '../components/projects/ClientCard'
import Layout from '../components/ui/layout'
import {MDXProvider} from '@mdx-js/react'
import {MDXRenderer} from 'gatsby-plugin-mdx'
import ProjectList from '../components/projects/ProjectList'
import SEO from '../components/shared/seo'
import posed from 'react-pose'
import styled from 'styled-components'
import {useLayoutValue} from '../components/ui/layoutProvider'
import {useMediaQuery} from 'react-responsive'

const Work: React.FC<PageProps<any>> = ({data, ...props}) => {
  const {state, dispatch} = useLayoutValue()
  const isMobile = useMediaQuery({maxWidth: 1224})

  return (
    <>
      <SEO
        title="Projects | John Dore"
        keywords={[`Computational Art`, `T1`, `John Dore`, `Plotter Drawings`]}
      />
      <div className=" text-white w-full  bg-gray tracking-widest">
        <div className="mx-8 md:mx-10 text-5xl text-white md:text-7xl pt-20 leading-header font-normal text-3xl">
          work
        </div>
      </div>
      <main className=" bg-main mb-24 ">
        <div className="text-3xl md:text-4xl  mx-8 md:mx-10 leading-header text-black mt-10">
          clients
        </div>
        {/* divider */}
        <div className="w-screen border-b border-black mb-10"></div>
        <div className="flex flex-row  flex-wrap mx-8 md:mx-10 w-full relative ">
          {data.clients.edges.map((client) => {
            return (
              <div className="">
                <ClientCard
                  date={client.node.date}
                  role={client.node.role}
                  image_1={client.node.image1.childImageSharp.fluid}
                  // image_2={client.node.image2.childImageSharp.fluid}
                  link={client.node.link}
                  tags={client.node.tags}
                  description={client.node.description}
                  title={client.node.title}></ClientCard>
              </div>
            )
          })}
        </div>
        <div className="text-3xl md:text-4xl text-black mx-8 md:mx-10 leading-header mt-16 ">
          art
        </div>
        <div className="w-screen border-b border-black mb-8"></div>
        <div className="px-8 md:px-0 md:mx-10 md:w-3/4 w-full ">
          {data.art.edges.map((project) => (
            <>
              <ProjectList data={props} project={project}></ProjectList>
            </>
          ))}
        </div>
      </main>
    </>
  )
}

// TODO: remove remnants of image2 from this component and the json data
export const query = graphql`
  {
    art: allMdx(sort: {order: DESC, fields: frontmatter___date}) {
      edges {
        node {
          id
          body
          excerpt
          slug
          frontmatter {
            title
            date
            excerpt
          }
        }
      }
    }
    clients: allClientsJson(filter: {}) {
      edges {
        node {
          image1 {
            id
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
              original {
                src
              }
            }
            sourceInstanceName
          }

          date
          description
          role
          title
          link
          technology
          tags
        }
      }
    }
  }
`

export default Work
