import {Link, PageProps, graphql} from 'gatsby'
import React, {useEffect, useState} from 'react'
import {animated as a, useSpring} from 'react-spring'

import {MDXProvider} from '@mdx-js/react'
import {MDXRenderer} from 'gatsby-plugin-mdx'
import posed from 'react-pose'
import styled from 'styled-components'

// animated react spring div
const Divider = styled(a.div)`
  position: relative;
  width: 100%;
  border-bottom: 1px;
  border-color: white;
  border-style: solid;
  will-change: width, opacity;
`

const ProjectList: React.FC<any> = ({project}) => {
  const [loading, set] = useState(false)
  const {width, opacity} = useSpring({
    width: `${loading ? 100 : 0}%`,
    opacity: loading ? 0 : 1,
    config: {mass: 2, tension: 200, friction: 80},
  })
  const [open, setOpen] = useState(true)
  const [symbol, setSymbol] = useState('-')

  const handleChange = () => {
    setOpen(!open)
    setSymbol(open === true ? '>' : '-')
  }
  useEffect(() => {
    set(true)
  }, [])
  return (
    <>
      <div className="w-full mt-6 ">
        <div className="flex text-black justify-start">
          <Link
            className="self-center hover:text-white md:text-lg font-medium text-sm py-2"
            to={project.node.slug}>
            {project.node.frontmatter.title}
          </Link>
          <h1 className="font-mono text-black text-sm px-2 self-center md:text-xs">
            // [{project.node.frontmatter.date}]
          </h1>
          <div
            onClick={(e) => handleChange()}
            className="mr-2 md:text-sm cursor-pointer self-center hover:text-white text-m text-jet self-center">
            {symbol}
          </div>
        </div>
        <Divider className="" style={{opacity: opacity.interpolate((o) => 1 - o), width}}></Divider>
        <Content className="overflow-hidden text-jet" pose={open === true ? 'open' : 'closed'}>
          <MDXProvider
            // data-excerpt method of generating excerpt is much more verbose and less useful than frontmatter?
            // leaving in place for reference
            components={{
              wrapper: ({onlyExcerpt = true, children}) => {
                let updatedChildren = [...children]

                if (onlyExcerpt) {
                  updatedChildren = children.filter((child, _) => {
                    return child.props && child.props['data-excerpt']
                  })
                }
                return (
                  <>
                    <div className="my-2 text-sm">{project.node.frontmatter.excerpt}</div>
                  </>
                )
              },
            }}>
            <MDXRenderer onlyExcerpt={true}>{project.node.body}</MDXRenderer>
          </MDXProvider>
        </Content>
        <Divider className="" style={{opacity: opacity.interpolate((o) => 1 - o), width}}></Divider>
      </div>
    </>
  )
}

// styles TODO: import these from another file
const SlideDiv = posed.div({
  enter: {
    x: 0,
    opacity: 0,
    width: '0%',
    borderColor: 'rgba(255, 255, 255, 1)',
    transition: {
      // background: {ease: 'linear', damping: 20, delay: 100},
      width: {ease: 'linear', damping: 20, delay: 100, duration: 1000},
    },
  },
  exit: {
    x: 0,
    width: 'inherit',
    opacity: 100,
    borderColor: 'rgba(255, 255, 255, 1)',
    // background: 'rgba(255, 255, 255, 1)',
    transition: {
      // background: {ease: 'linear', damping: 20, delay: 100, duration: 1000},
      width: {ease: 'linear', damping: 500, delay: 100, duration: 1000},
    },
  },
})

const Content = posed.div({
  closed: {height: 0},
  open: {height: 'auto'},
})
const LinkUnderline = styled(SlideDiv)`
  position: relative;
  /* height: 1rem; */
  border-color: #fff;
  border-bottom: 1px;
  border-style: solid;
`

export default ProjectList
